import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore, IDetailsStoreAssociations } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Profile } from "../../types";
import { BatchesStore } from "./Batches";

@Module({
  name: "ProfileDetails",
  store,
  dynamic: true,
  namespaced: true,
})
class ProfilesDetails extends DetailsPageStore<Profile> {
  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: ({ id }) => {
        return `/profiles/${id}/${BatchesStore.selectedBatchId}/overview`;
      },
    },
    graph: {
      _apiPath: ({ id }) => {
        return `/profiles/${id}/${BatchesStore.selectedBatchId}/graph`;
      },
    },
    reviews: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/reviews`,
    },
    reviewsFilters: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/reviews/filters`,
    },
    businesses: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/businesses`,
    },
    businessesFilters: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/businesses/filters`,
    },
    profiles: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/profiles`,
    },
    profilesFilters: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/profiles/filters`,
    },
    ips: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/ips`,
    },
    ipsFilters: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/ips/filters`,
    },
    isps: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/isps`,
    },
    ispsFilters: {
      _apiPath: ({ id }) => `/profiles/${id}/${BatchesStore.selectedBatchId}/isps/filters`,
    },
  };

  get itemApiPath(): (id?: string) => string {
    return (id) => {
      return `/profiles/${id}/${BatchesStore.selectedBatchId}/details`;
    };
  }

  get api() {
    return $api;
  }

  get profileEmail() {
    return (itemId: string) => {
      const profile = this.item(itemId);

      if (profile) {
        return profile.email;
      }
      return "";
    };
  }
}

export const ProfileDetailsStore = getModule(ProfilesDetails);
