import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const IpsListing = () => import("../views/ips/IPsListing.vue");
const IpDetails = () => import("../views/ips/IPDetails.vue");
const IpOverviewTab = () => import("../views/ips/IPOverviewTab.vue");
const IpReviewsTab = () => import("../views/ips/IPReviewsTab.vue");
const IpProfilesTab = () => import("../views/ips/IPProfilesTab.vue");
const IpBusinessesTab = () => import("../views/ips/IPBusinessesTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "ips/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "IPs",
      breadcrumbOverrideName: "IPsListing",
    },
    children: [
      {
        path: "",
        name: "IPsListing",
        component: IpsListing,
        meta: {
          breadcrumb: "IPs",
        },
      },
      {
        path: ":id",
        component: IpDetails,
        name: "ip>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$route.params.id;
          },
          breadcrumbOverrideName: "ip>overview",
        },
        redirect: { name: "ip>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "ip>overview",
                component: IpOverviewTab,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "reviews",
                name: "ip>reviews",
                component: IpReviewsTab,
                meta: {
                  breadcrumb: "Reviews",
                },
              },
              {
                path: "businesses",
                name: "ip>businesses",
                component: IpBusinessesTab,
                meta: {
                  breadcrumb: "Businesses",
                },
              },
              {
                path: "profiles",
                name: "ip>profiles",
                component: IpProfilesTab,
                meta: {
                  breadcrumb: "Profiles",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
