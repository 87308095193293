import BusinessGraphNode from "./graph/BusinessGraphNode.vue";
import ProfileGraphNode from "./graph/ProfileGraphNode.vue";
import ReviewGraphNode from "./graph/ReviewGraphNode.vue";
import IPGraphNode from "./graph/IPGraphNode.vue";
import Vue from "vue";

Vue.component("business-graph-node", BusinessGraphNode);
Vue.component("profile-graph-node", ProfileGraphNode);
Vue.component("review-graph-node", ReviewGraphNode);
Vue.component("ip-graph-node", IPGraphNode);
