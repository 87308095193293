import { Module, getModule } from "vuex-module-decorators";
import { IListStoreAssociations, ListingPageStore } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Profile } from "../../types";
import { BatchesStore } from "./Batches";

@Module({
  name: "BusinessesList",
  store,
  dynamic: true,
  namespaced: true,
})
class BusinessesList extends ListingPageStore<Profile> {
  get itemsApiPath() {
    return `/businesses/${BatchesStore.selectedBatchId}`;
  }

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: () => `/businesses/${BatchesStore.selectedBatchId}/filters`,
    },
    model: {
      _apiPath: () => `/businesses/${BatchesStore.selectedBatchId}/model`,
    },
  };

  get api() {
    return $api;
  }
}

export const BusinessesListStore = getModule(BusinessesList);
