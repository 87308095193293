import { RawLocation } from "vue-router";

export enum MainSections {
  dashboard = "dashboard",
  businesses = "businesses",
  profiles = "profiles",
  ips = "ips",
  isps = "isps",
  reviews = "reviews",
}

export enum EntityTypes {
  businesses = "businesses",
  profiles = "profiles",
  ips = "ips",
  isps = "isps",
  reviews = "reviews",
}

export enum ReviewTabs {
  overview = "overview",
  relatedReviews = "relatedReviews",
  exactReviews = "exactReviews",
  closeReviews = "closeReviews",
  notes = "notes",
}

export enum ProfileTabs {
  overview = "overview",
  reviews = "reviews",
  profiles = "profiles",
  businesses = "businesses",
  ips = "ips",
  isps = "isps",
  notes = "notes",
}

export enum BusinessTabs {
  overview = "overview",
  reviews = "reviews",
  profiles = "profiles",
  businesses = "businesses",
  ips = "ips",
  isps = "isps",
  notes = "notes",
}

export enum IPsTabs {
  overview = "overview",
  reviews = "reviews",
  profiles = "profiles",
  businesses = "businesses",
}

export enum ISPsTabs {
  overview = "overview",
  ips = "ips",
  businesses = "businesses",
  profiles = "profiles",
  reviews = "reviews",
}

export enum ProfileOverviewSections {
  overview = "overview",
  dailyReviewsBreakdown = "dailyReviewsBreakdown",
  reviewsBreakdown = "reviewsBreakdown",
  reviews = "reviews",
  ips = "ips",
  isps = "isps",
  scoresHistory = "scoresHistory",
  businesses = "businesses",
  profiles = "profiles",
}

export enum BusinessOverviewSections {
  overview = "overview",
  scoresHistory = "scoresHistory",
  dailyReviewsBreakdown = "dailyReviewsBreakdown",
  reviewsBreakdown = "reviewsBreakdown",
  reviews = "reviews",
  ips = "ips",
  isps = "isps",
  businesses = "businesses",
  profiles = "profiles",
}

export enum IPOverviewSections {
  overview = "overview",
  details = "details",
  dailyReviewsBreakdown = "dailyReviewsBreakdown",
}

export enum ISPOverviewSections {
  overview = "overview",
  countriesBreakdown = "countriesBreakdown",
}

export enum ReviewOverviewSections {
  content = "content",
  details = "details",
}

export type EntityTabs = {
  [EntityTypes.reviews]: ReviewTabs;
  [EntityTypes.profiles]: ProfileTabs;
  [EntityTypes.businesses]: BusinessTabs;
  [EntityTypes.ips]: IPsTabs;
  [EntityTypes.isps]: ISPsTabs;
};

export interface SidebarLinks {
  key: MainSections;
  label: string;
  icon: string;
  to: string | RawLocation;
  tooltip?: string;
}
