import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const ReviewsListing = () => import("../views/reviews/ReviewsListing.vue");
const ReviewDetails = () => import("../views/reviews/ReviewDetails.vue");
const ReviewOverviewTab = () => import("../views/reviews/ReviewOverviewTab.vue");
const ReviewNotesTab = () => import("../views/reviews/ReviewNotesTab.vue");
const ReviewRelatedReviewsTab = () => import("../views/reviews/ReviewRelatedReviewsTab.vue");
const ReviewExactReviewsTab = () => import("../views/reviews/ReviewExactReviewsTab.vue");
const ReviewCloseReviewsTab = () => import("../views/reviews/ReviewCloseReviewsTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "reviews/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Reviews",
      breadcrumbOverrideName: "ReviewsListing",
    },
    children: [
      {
        path: "",
        name: "ReviewsListing",
        component: ReviewsListing,
        meta: {
          breadcrumb: "Reviews",
        },
      },
      {
        path: ":id",
        component: ReviewDetails,
        name: "review>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$route.params.id;
          },
          breadcrumbOverrideName: "isp>overview",
        },
        redirect: { name: "review>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "review>overview",
                component: ReviewOverviewTab,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "related_reviews",
                name: "review>relatedReviews",
                component: ReviewRelatedReviewsTab,
                meta: {
                  breadcrumb: "Related Reviews",
                },
              },
              {
                path: "exact_reviews",
                name: "review>exactReviews",
                component: ReviewExactReviewsTab,
                meta: {
                  breadcrumb: "Exact Reviews",
                },
              },
              {
                path: "close_reviews",
                name: "review>closeReviews",
                component: ReviewCloseReviewsTab,
                meta: {
                  breadcrumb: "Close Reviews",
                },
              },
              {
                path: "notes",
                name: "review>notes",
                component: ReviewNotesTab,
                meta: {
                  breadcrumb: "Notes",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
