import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore, IDetailsStoreAssociations } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { IP } from "../../types";
import { BatchesStore } from "./Batches";

@Module({
  name: "IPDetails",
  store,
  dynamic: true,
  namespaced: true,
})
class IPDetails extends DetailsPageStore<IP> {
  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: ({ id }) => {
        return `/ips/${id}/${BatchesStore.selectedBatchId}/overview`;
      },
    },
    graph: {
      _apiPath: ({ id }) => {
        return `/ips/${id}/${BatchesStore.selectedBatchId}/graph`;
      },
    },
    reviews: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/reviews`,
    },
    reviewsFilters: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/reviews/filters`,
    },
    profiles: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/profiles`,
    },
    profilesFilters: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/profiles/filters`,
    },
    businesses: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/businesses`,
    },
    businessesFilters: {
      _apiPath: ({ id }) => `/ips/${id}/${BatchesStore.selectedBatchId}/businesses/filters`,
    },
  };

  get itemApiPath(): (id?: string) => string {
    return (id) => {
      return `/ips/${id}/${BatchesStore.selectedBatchId}/details`;
    };
  }

  get api() {
    return $api;
  }
}

export const IPDetailsStore = getModule(IPDetails);
