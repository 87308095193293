
import { Component, Vue } from "vue-property-decorator";
import { PortalTarget } from "portal-vue";

@Component({
  name: "App",
  components: {
    PortalTarget,
  },
})
export default class App extends Vue {}
