import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const ProfilesListing = () => import("../views/profiles/ProfilesListing.vue");
const ProfileDetails = () => import("../views/profiles/ProfileDetails.vue");
const ProfileOverview = () => import("../views/profiles/ProfileOverviewTab.vue");
const ProfileReviews = () => import("../views/profiles/ProfileReviewsTab.vue");
const ProfileBusinesses = () => import("../views/profiles/ProfileBusinessesTab.vue");
const ProfileISPs = () => import("../views/profiles/ProfileISPsTab.vue");
const ProfileIPs = () => import("../views/profiles/ProfileIPsTab.vue");
const ProfileProfiles = () => import("../views/profiles/ProfileProfilesTab.vue");
const ProfileNotes = () => import("../views/profiles/ProfileNotesTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "profiles/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Profiles",
      breadcrumbOverrideName: "ProfilesListing",
    },
    children: [
      {
        path: "",
        name: "ProfilesListing",
        component: ProfilesListing,
        meta: {
          breadcrumb: "Profiles",
        },
      },
      {
        path: ":id",
        component: ProfileDetails,
        name: "profile>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["ProfileDetails/profileEmail"](this.$route.params.id);
          },
          breadcrumbOverrideName: "profile>overview",
        },
        redirect: { name: "profile>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "profile>overview",
                component: ProfileOverview,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "reviews",
                name: "profile>reviews",
                component: ProfileReviews,
                meta: {
                  breadcrumb: "Reviews",
                },
              },
              {
                path: "businesses",
                name: "profile>businesses",
                component: ProfileBusinesses,
                meta: {
                  breadcrumb: "Businesses",
                },
              },
              {
                path: "ips",
                name: "profile>ips",
                component: ProfileIPs,
                meta: {
                  breadcrumb: "IPs",
                },
              },
              {
                path: "isps",
                name: "profile>isps",
                component: ProfileISPs,
                meta: {
                  breadcrumb: "ISPs",
                },
              },
              {
                path: "profiles",
                name: "profile>profiles",
                component: ProfileProfiles,
                meta: {
                  breadcrumb: "Profiles",
                },
              },
              {
                path: "notes",
                name: "profile>notes",
                component: ProfileNotes,
                meta: {
                  breadcrumb: "Notes",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
