import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore, IDetailsStoreAssociations } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Review } from "../../types";
import { BatchesStore } from "./Batches";

@Module({
  name: "ReviewDetails",
  store,
  dynamic: true,
  namespaced: true,
})
class ReviewDetails extends DetailsPageStore<Review> {
  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: ({ id }) => {
        return `/reviews/${id}/${BatchesStore.selectedBatchId}/overview`;
      },
    },
    relatedReviews: {
      _apiPath: ({ id }) => `/reviews/${id}/${BatchesStore.selectedBatchId}/related_reviews`,
    },
    relatedReviewsFilters: {
      _apiPath: ({ id }) =>
        `/reviews/${id}/${BatchesStore.selectedBatchId}/related_reviews/filters`,
    },
    exactReviews: {
      _apiPath: ({ id }) => `/reviews/${id}/${BatchesStore.selectedBatchId}/exact_reviews`,
    },
    exactReviewsFilters: {
      _apiPath: ({ id }) => `/reviews/${id}/${BatchesStore.selectedBatchId}/exact_reviews/filters`,
    },
    closeReviews: {
      _apiPath: ({ id }) => `/reviews/${id}/${BatchesStore.selectedBatchId}/close_reviews`,
    },
    closeReviewsFilters: {
      _apiPath: ({ id }) => `/reviews/${id}/${BatchesStore.selectedBatchId}/close_reviews/filters`,
    },
  };

  get itemApiPath(): (id?: string) => string {
    return (id) => {
      return `/reviews/${id}/${BatchesStore.selectedBatchId}/details`;
    };
  }

  get api() {
    return $api;
  }
}

export const ReviewDetailsStore = getModule(ReviewDetails);
